import React, { useEffect, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Checkbox } from 'primereact/checkbox';
import { BsArrowCounterclockwise, BsSearch, BsX } from 'react-icons/bs';
import Loader from '../Loader';
import { Form } from 'react-bootstrap';
import fetcher from '../../api/fetcher';

const MultiselectMoreFileds = ({
  options,
  value = [],
  optionLabel,
  optionValue,
  disabled,
  onChange,
  isAnalisi = false,
  checked = [],
  isCanvas = false
}) => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [title, setTitle] = useState('');
  const [attori, setAttori] = useState('');
  const [director, setDirector] = useState('');
  const [keywords, setKeywords] = useState([]);
  const [selectedKeyword, setSelectedKeyword] = useState('');
  const [results, setResults] = useState([]);
  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchKeywords = async () => {
      try {
        const response = await fetcher(`/liste/keywords`);
        if (!response.status === 200) {
          throw new Error(`Errore nella risposta: ${response.status}`);
        }
        const data = response.data;
        setKeywords(data.data || []);
      } catch (error) {
        console.error('Errore durante la chiamata API:', error);
      } finally {
      }
    };
    fetchKeywords();
  }, []);

  const handleSelectChange = (event) => {
    setSelectedKeyword(event.target.value); // Aggiorna lo stato della selezione
  };

  const handleSearch = () => {
    setLoading(true);
    setTimeout(() => {
      const filteredResults = options.filter((item) => {
        return (
          (!title ||
            (item.descrizione && item.descrizione.toLowerCase().includes(title.toLowerCase()))) 
            &&
          (!attori || (item.attori && item.attori.toLowerCase().includes(attori.toLowerCase()))) 
          &&
          (!director ||
            (item.regia && item.regia.toLowerCase().includes(director.toLowerCase()))) 
          &&
          (!selectedKeyword ||
            (item.keywords && item.keywords.toLowerCase().includes(selectedKeyword.toLowerCase())))
        );
      });
      setResults(filteredResults);
      setLoading(false);
    }, 1000);
  };

  const openDialog = () => {
    let valori = [];
    setIsDialogVisible(true);
    if (value.length > 0 && isAnalisi) {
      value.forEach((element) => {
        let data = options.find((res) => res.valore === element);
        valori = [...valori, data];
        setSelectedItems(valori);
      });
    } else {
      setSelectedItems(checked);
    }
  };

  const closeDialog = () => {
    setIsDialogVisible(false);
    setResults([]);
  };

  const onRowSelect = (e) => {
    const selected = [...selectedItems];
    const valore = [...value];
    if (e.checked) {
      selected.push(e.value);
      valore.push(e.value.valore);
    } else {
      const index = selected.findIndex((item) => item[optionValue] === e.value[optionValue]);
      if (index > -1) {
        selected.splice(index, 1);
        valore.splice(index, 1);
      }
    }
    setSelectedItems(selected);
    if (isAnalisi) {
      onChange(valore); // Pass selected items to parent
    } else {
      onChange(selected); // Pass selected items to parent
    }
  };

  const checkboxTemplate = (rowData) => {
    return (
      <Checkbox
        inputId={rowData[optionValue]}
        value={rowData}
        checked={selectedItems.some((item) => item[optionValue] === rowData[optionValue])}
        onChange={onRowSelect}
      />
    );
  };

  const handleReset = () => {
    setTitle('');
    setAttori('');
    setDirector('');
    setSelectedKeyword('');
    setResults([]);
  };

  return (
    <div>
      <div className="text-center">
        <button
          className="btn btn-blue-cinetel mx-1"
          title="Ricerca Avanzata"
          disabled={disabled}
          onClick={openDialog}>
          <BsSearch className="position-relative me-1" style={{ marginBottom: '3px' }} />
          {isCanvas ? null : 'Ricerca Avanzata'}
        </button>
      </div>
      <Dialog
        header="Filtro Avanzato"
        onKeyDown={(e) => e.key === 'Enter' && handleSearch()}
        footer={
          <div className="p-col-12">
            <div className="d-flex justify-content-end">
              <button
                className="btn btn-blue-cinetel mx-1"
                title="cerca"
                disabled={title === '' && attori === '' && director === '' && keywords === ''}
                onClick={handleSearch}>
                <BsSearch className="position-relative me-1" style={{ marginBottom: '3px' }} />
                {'Cerca'}
              </button>
              <button
                className="btn btn-warning mx-1"
                title="reset"
                disabled={title === '' && attori === '' && director === '' && keywords === ''}
                onClick={handleReset}>
                <BsArrowCounterclockwise
                  className="position-relative me-1"
                  style={{ marginBottom: '3px' }}
                />
                {'Reset Ricerca'}
              </button>
              <button
                className="btn btn-danger mx-1"
                title="closeSearch"
                disabled={title === '' && attori === '' && director === '' && keywords === ''}
                onClick={closeDialog}>
                <BsX className="position-relative me-1" style={{ marginBottom: '3px' }} />
                {'Seleziona e Chiudi'}
              </button>
            </div>
          </div>
        }
        visible={isDialogVisible}
        style={{ width: '70vw' }}
        onHide={closeDialog}>
        <div>
          <h3>Ricerca Avanzata</h3>
          <div className="p-grid p-fluid">
            <div className="p-col-12 p-md-2">
              <InputText
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                placeholder="Titolo"
              />
            </div>
            <div className="p-col-12 p-md-2">
              <InputText
                value={attori}
                onChange={(e) => setAttori(e.target.value)}
                placeholder="Attori"
              />
            </div>
            <div className="p-col-12 p-md-2">
              <InputText
                value={director}
                onChange={(e) => setDirector(e.target.value)}
                placeholder="Regia"
              />
            </div>
            <div className="p-col-12 p-md-2">
              <Form.Group>
                <Form.Select value={selectedKeyword} onChange={handleSelectChange}>
                  <option value="" disabled style={{ color: 'gray' }}>
                    Seleziona una Keyword
                  </option>
                  {keywords.map((keyword, index) => (
                    <option key={index} value={keyword.valore_caratteristica}>
                      {keyword.valore_caratteristica}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </div>
          </div>
          {loading ? (
            <div className="d-flex justify-content-center">
              <Loader messaggio={'Caricamento informazioni film...'} />
            </div>
          ) : (
            <DataTable value={results} emptyMessage="Nessun Risultato trovato">
              <Column body={checkboxTemplate} header="" />
              <Column field="descrizione" header="Titolo" />
              <Column field="attori" header="Attori" />
              <Column field="regia" header="Regia" />
              <Column field="keywords" header="keywords" />
            </DataTable>
          )}
        </div>
      </Dialog>
    </div>
  );
};

export default MultiselectMoreFileds;
